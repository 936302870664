.location__intro {
    h1 {
        max-width: 500px;
        margin-bottom: 35px;
        color: $plum;
    }
    @media screen and (max-width: 600px) {
        margin-top: 25px;    
    }
}

.location__map {
    background: #EEE;
    height: 600px;
    @media screen and (max-width: 600px) {
        height: 300px;    
    }
}

.location__transport {    
    & > p {
        margin-top: 100px;
        font-weight: 700;
        text-align: center;
        @include font-size(13);
    }
    .location__transport--icons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        margin: 35px auto 100px;
        max-width: 900px;
        figure {
            text-align: center;
            img {
                width: 100px;
                height: 112px;
                margin: 0;
            }
            figcaption {
                margin-top: 5px;
                line-height: 120%;
                @include font-size(18); 
                @extend %condensed-font;
                span {
                    display: block;
                    font-weight: 700;
                    @include font-size(32);
                }
            }
            &:nth-child(1) {
                figcaption {
                    color: $green;
                    span {
                        color: $slate;
                    }
                }
            }
            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                figcaption {
                    padding-top: 5px;
                    color: $slate;
                    span {
                        padding-bottom: 10px;
                        color: $green;
                    }
                }
            }            
        }
        @media screen and (max-width: 768px) {
            margin: 35px 0;
            figure {
                flex-basis: 48%;
                figcaption {
                    @include font-size(14);
                    span {
                        @include font-size(24);
                    }
                }
            }
        }
    }
    .location__transport--description {
        padding-bottom: 250px;
        img {
            margin: 0;
        }
        p {
            padding-left: 30px;
            &:first-of-type {            
                margin-top: 150px;
            }
        }
        @media screen and (max-width: 1024px) {
            padding-bottom: 50px;
        }
        @media screen and (max-width: 768px) {
            padding-bottom: 35px;
            p {
                padding: 0;
                &:first-of-type {
                    margin-top: 35px;
                }
            }
        }
    }
}

.location__triangle-1 {
    top: 40px;
    left: -120px;
    transform: rotate(-20deg);
}
.location__triangle-2 {
    top: -20px;
    right: -100px;
    transform: rotate(40deg);
}
.location__triangle-3 {
    bottom: 220px;
    left: 400px;
    width: 40px;
    height: 40px;
    transform: rotate(-40deg);
}
.location__triangle-4 {
    top: 20px;
    right: 150px;
    transform: rotate(-10deg);
}
.location__triangle-5 {
    bottom: 50px;
    left: 750px;
    transform: rotate(40deg);
}