.offices__intro {
    h1 {
        max-width: 600px;
        color: $red;
    }
    h2 {
        max-width: 710px;
        color: $slate;
    }
}

.offices__triangle-1 {
    top: 600px;
    left: 60px;
    transform: rotate(10deg);
}
.offices__triangle-2 {
    top: 90px;
    right: 350px;
    transform: rotate(40deg);
}
.offices__triangle-3 {
    top: 300px;
    right: 180px;
    transform: rotate(-35deg);
}
.offices__triangle-4 {
    bottom: 170px;
    right: 100px;
    width: 35px;
    height: 35px;
    transform: rotate(10deg);
}