.shops__intro {
    position: relative;
    padding-top: 50px;
    h1 {
        max-width: 450px;
        line-height: 90%;
        color: $orange;
        @media screen and (max-width: 1024px) {
            max-width: 330px;
        }
    }
    h2 {
        max-width: 650px;
        color: $plum;
        @media screen and (max-width: 1024px) {
            max-width: 520px;
        }
    }
    figure {
        text-align: center;
        img {
            width: 150px;
            margin: 40px 0 0 0;
        }
        figcaption {
            margin-top: 15px;
            line-height: 120%;
            @include font-size(32);
            @extend %condensed-font;
            span {
                display: block;
                padding-bottom: 10px;
                color: $green;
                font-weight: 700;
                @include font-size(56);
            }
        }
        @media screen and (max-width: 600px) {
            flex-basis: 50%; 
            figcaption {
                @include font-size(18); 
                span {
                    @include font-size(36);
                }
            }
            &:nth-of-type(1), &:nth-of-type(2) {
                margin-bottom: 50px;
            }
        }
        @media screen and (max-width: 400px) {
            figcaption {
                @include font-size(16); 
                span {
                    @include font-size(30);
                }
            }
        }
    }
    .shops__intro-triangle {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 231px;
        height: 234px;
        padding: 85px 55px 0 30px;
        background: url('/images/home__main-triangle.svg') top left no-repeat;
        background-size: cover;        
        line-height: 90%;
        text-align: right;
        color: #FFF;
        @include font-size(32);
        @extend %condensed-font;
        @media screen and (max-width: 1024px) {
            top: -35px;   
            right: 40px;
        }
        @media screen and (max-width: 600px) {
            position: static;
            width: 100%;
            height: auto;
            padding: 15px;
            margin-top: 35px;
            background: $slate;
            text-align: center;
            line-height: 100%;
        }
    }
    .shops__intro--images {
        padding: 75px 0 200px;
        .shops__intro--image1 {
            padding-top: 100px;
        }
        @media screen and (max-width: 1024px) {
            padding-top: 25px;
            padding-bottom: 50px;    
        }
        @media screen and (max-width: 768px) {
            padding: 0;
            .shops__intro--image1 {
                padding-top: 0;
                margin-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 600px) {
        padding-top: 25px;    
    }
}

.shops__businesses {
    h3 {
        max-width: 240px;
        margin-top: 15px;
        color: $slate;
    }
    img {
        display: block;
        max-width: 180px;
        margin-top: 50px;
    }
    @media screen and (max-width: 600px) {
        text-align: center;
        padding-bottom: 50px;
        h3 {
            max-width: none;
        }
        img {
            margin: 35px auto 0;
        }
    }
}

.shops__details {
    padding-bottom: 150px;
    h2 {
        max-width: 600px;
        margin-bottom: 35px;
        line-height: 90%;
        color: $orange;
        @include font-size(56);
        @media screen and (max-width: 1024px) {
            @include font-size(40);    
        }
        @media screen and (max-width: 600px) {
            @include font-size(32);    
        }
    }
    .shops__details--stats {
        display: flex;
        justify-content: space-between;
        max-width: 550px;
        margin-top: 50px;
        dl {
            dt, dd {
                line-height: 100%;
                @extend %condensed-font;      
            }
            dt {
                color: $fuschia;
                @include font-size(64);
            }
            dd {
                max-width: 150px;
                margin: 0;
                color: $slate;
                @include font-size(22); 
            }
        }        
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 35px;
        .shops__details--stats { 
            display: block;
            text-align: center;
            max-width: none;
            dl dd {
                max-width: none;
            }
        }
    }
}

.shops__location {
    h2 {
        max-width: 500px;
        line-height: 90%;
        color: $pink;
        @include font-size(56);
        @media screen and (max-width: 1024px) {
            @include font-size(40);    
        }
        @media screen and (max-width: 600px) {
            @include font-size(32);    
        }
    }
    .shops__location--cgi {
        position: relative;
        margin: 50px 0;
        img {
            margin: 0;
            &:not(.original) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;                
            }
            &.svg {
                opacity: 0;
                transition: opacity .2s ease-in-out;
                &.active, &.all {
                    opacity: 1;
                }
            }
        }
        nav {
            position: absolute;
            bottom: 35px;
            left: 35px;
            li {
                margin: 8px 0 0;
                a {
                    display: block;
                    padding: 5px 15px 7px;
                    border: 1px solid #FFF;
                    background: #FFF;
                    transition: all .2s ease;
                    color: $plum;
                    @include font-size(15);
                    @extend %condensed-font;
                    &:hover, &.active {
                        background: $orange;
                        text-decoration: none;
                        color: #FFF;
                    }
                }
            }
            @media screen and (max-width: 600px) {
                display: none;    
            }
        }
    }
    ul li {
        margin-bottom: 35px;
        a {
            display: block;
            max-width: 180px;
            line-height: 100%;
            color: $slate;
            @include font-size(22);
            @extend %condensed-font;
            &:hover {
                text-decoration: none;
                color: $orange;
            }
            @media screen and (max-width: 600px) {
                max-width: none;
                text-align: center;
            }
        }
    }
    .shops__location--sizes {
        dl {
            margin-top: 50px;
            dt, dd {
                line-height: 100%;
                @extend %condensed-font;      
            }
            dt {
                @include font-size(22);
            }
            dd {
                margin: 0;
                line-height: 80%;
                color: $light_grey;
                @include font-size(56); 
            }
            @media screen and (max-width: 1024px) {                
                dt {
                    @include font-size(20); 
                } 
                dd {
                    @include font-size(40); 
                }    
            }
            @media screen and (max-width: 600px) {
                margin-bottom: 0;
                dt, dd {
                    text-align: center;
                }
                dt {
                    @include font-size(18); 
                } 
                dd {
                    @include font-size(32); 
                }    
            }
        }
    }
}

.shops__triangle-1 {
    top: 450px;
    left: 70px;
    transform: rotate(-20deg);
}
.shops__triangle-2 {
    top: -170px;
    left: 300px;
    width: 40px;
    height: 40px;
    transform: rotate(40deg);
}
.shops__triangle-3 {
    top: -100px;
    left: 780px;
    width: 50px;
    height: 50px;
    transform: rotate(15deg);
}
.shops__triangle-4 {
    top: -450px;
    right: 300px;
    transform: rotate(-25deg);
}
.shops__triangle-5 {
    top: -300px;
    right: 50px;
    transform: rotate(-45deg);
}
.shops__triangle-6 {
    top: 20px;
    left: 320px;
    width: 40px;
    height: 40px;
    transform: rotate(5deg);
}
.shops__triangle-7 {
    top: -70px;
    right: 100px;
    transform: rotate(35deg);
}
.shops__triangle-8 {
    bottom: 200px;
    left: 150px;
    width: 40px;
    height: 40px;
    transform: rotate(15deg);
}
.shops__triangle-9 {
    bottom: 250px;
    right: 150px;
    transform: rotate(-20deg);
}