body.homepage {
    h1, h2 {            
        color: $plum;
    }

    .carousel-indicators {
        bottom: 25px;
        li {
            width: 120px;
            background: #FFF;
            border-top: 0;
            margin: 0 5px;
        }
    }

    .home__sublinks {
        li {
            a {                
                line-height: 100%;
                @include font-size(22);
                @extend %condensed-font;
                &:hover {
                    text-decoration: none;
                }
            }
            &:first-child a {
                color: $green;
            }
            &:nth-child(2) a {
                color: $purple;
            }
            &:nth-child(3) a {
                color: $orange;
            }
            &:nth-child(4) a {
                color: $red;
            }
        }
    }

    .home__main {
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
        .home__main-triangle {
            position: absolute;
            top: -50px;
            left: 80px;
            z-index: 300;
            width: 231px;
            height: 234px;
            padding: 85px 55px 0 30px;
            background: url('/images/home__main-triangle.svg') top left no-repeat;
            background-size: cover;            
            line-height: 90%;
            text-align: right;
            color: #FFF;
            @include font-size(32);
            @extend %condensed-font;
            @media screen and (max-width: 600px) {
                width: 120px;
                height: 122px;
                left: auto;
                right: 10px;
                top: -20px;
                @include font-size(12);
                padding: 55px 30px 0 25px;
            }
        }
    }

    .home__message {
        position: relative;
        h1 {
            max-width: 700px;
            @include font-size(40); 
            @media screen and (max-width: 600px) {
                @include font-size(30);
            }
            @media screen and (max-width: 400px) {
                @include font-size(28);
            }
        }        
    }

    .home__hero {
        position: relative;
        overflow: hidden;
	    padding-bottom: 60%;
        margin: 50px 0;
        img {
            position: absolute;
            top: 0%;
            left: 50%;
            max-width: 200%;
            width: 100%;
            margin: 0;
            transform: translateX(-50%) scale(0.5);
        }
        @media screen and (max-width: 600px) {
            margin: 50px 0;    
        }
    }
    
    .home__description {
        .home__description--heading {
            position: relative;
            padding-top: 150px;
            h2 {
                max-width: 530px;
            }
            @media screen and (max-width: 1024px) {
                padding-top: 0;
            }
            @media screen and (max-width: 768px) {
                h2 {
                    max-width: none;
                }
            }
        }
        .row > div:nth-child(2) > p:first-child {
            margin-top: 50px;
            @media screen and (max-width: 1024px) {
                margin-top: 0;    
            }
            @media screen and (max-width: 768px) {
                margin-top: 35px;    
            }
        }
        p {
            @include font-size(15); 
            @media screen and (max-width: 1024px) {
                @include font-size(14); 
            } 
            @media screen and (max-width: 400px) {
                @include font-size(12); 
            }
        }
    }
    
    .home__panel-grid {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(40, 1fr);
        margin-top: 150px;
        .home__panel-grid--block {
            position: relative;
            figure {
                position: relative;
                margin-bottom: 25px;
                figcaption {
                    position: absolute;
                    bottom: 0;
                    left: 45px;
                    h3 {
                        max-width: 350px;
                        line-height: 80%;
                        color: #FFF;
                        @include font-size(38);
                        @extend %condensed-font;
                    }
                    @media screen and (max-width: 400px) {
                        left: 25px;    
                    }
                }
            }
            p {
                margin: 0 0 25px 45px;
                @media screen and (max-width: 400px) {
                    margin-left: 25px;    
                }
            }
            &:nth-child(1) {
                grid-area: 1 / 2 / 12 / 12;
                -ms-grid-row: 1;
                -ms-grid-row-span: 11;
                -ms-grid-column: 2;
                -ms-grid-column-span: 10;
            }
            &:nth-child(2) {
                grid-area: 9 / 13 / 20 / 24;
                -ms-grid-row: 9;
                -ms-grid-row-span: 11;
                -ms-grid-column: 13;
                -ms-grid-column-span: 11;
            }
            &:nth-child(3) {
                grid-area: 18 / 2 / 29 / 12;                
                -ms-grid-row: 18;
                -ms-grid-row-span: 11;
                -ms-grid-column: 2;
                -ms-grid-column-span: 10;
            }
            &:nth-child(4) {
                grid-area: 26 / 14 / 37 / 24;
                -ms-grid-row: 26;
                -ms-grid-row-span: 11;
                -ms-grid-column: 14;
                -ms-grid-column-span: 10;
            }
            &:nth-child(5) {
                grid-area: 31 / 3 / 41 / 12;
                -ms-grid-row: 31;
                -ms-grid-row-span: 10;
                -ms-grid-column: 3;
                -ms-grid-column-span: 9;
                p {
                    max-width: 280px;
                    margin: 0;
                    font-weight: 600;
                    @media screen and (max-width: 600px) {
                        max-width: none;
                        text-align: center;
                    }
                }
                .home__panel-grid--block-logos {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    .logo {
                        flex-basis: 48%;
                        margin-top: 50px;
                        img {
                            max-height: 56px;
                        }                        
                        @media screen and (max-width: 600px) {
                            text-align: center;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            display: block;
            margin-top: 50px;
            .home__panel-grid--block {
                padding: 15px 0;    
            }
        }
    }
    
    .home__transport {
        .wrapper {
            position: relative;
        }
        h2 {
            margin: 50px 0 100px;
            max-width: 780px;
            @include font-size(40); 
            @media screen and (max-width: 1024px) {
                margin: 50px 0;
                @include font-size(32); 
            }
            @media screen and (max-width: 600px) {
                @include font-size(30);
            }
            @media screen and (max-width: 400px) {
                @include font-size(26);
            }
        }
        p.intro {
            margin: 75px 0 10px;
            @media screen and (max-width: 768px) {
                margin-top: 0; 
                & ~ p:last-child {
                    margin-bottom: 50px;
                }
            }
        }
        .button {
            margin-top: 10px;
        }
        .home__transport--icons {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 75px auto 100px;
            max-width: 900px;
            figure {
                text-align: center;
                img {
                    width: 135px;
                    height: 112px;
                }
                figcaption {
                    margin-top: 15px;
                    line-height: 120%;
                    @include font-size(18); 
                    @extend %condensed-font;
                    span {
                        display: block;
                        font-weight: 700;
                        @include font-size(40);
                    }
                }
                &:nth-child(1), &:nth-child(2) {
                    figcaption {
                        color: $green;
                        span {
                            color: $slate;
                        }
                    }
                }
                &:nth-child(3), &:nth-child(4) {
                    figcaption {
                        padding-top: 5px;
                        color: $slate;
                        span {
                            padding-bottom: 10px;
                            color: $green;
                        }
                    }
                }
                @media screen and (max-width: 600px) {
                    flex-basis: 50%; 
                    figcaption {
                        @include font-size(18); 
                        span {
                            @include font-size(36);
                        }
                    }
                    &:nth-of-type(1), &:nth-of-type(2) {
                        margin-bottom: 50px;
                    }
                }
                @media screen and (max-width: 400px) {
                    figcaption {
                        @include font-size(16); 
                        span {
                            @include font-size(30);
                        }
                    }
                }
            }
            @media screen and (max-width: 600px) {
                margin: 50px 0;    
            }
        }
    }
    
    .home__contact {
        padding: 25px 0;
        background: $pink;
        h2, h4 {
            font-weight: 700;
            color: #FFF;
        }
        h2 {
            max-width: 580px;
            @include font-size(38);
            @media screen and (max-width: 1024px) {
                @include font-size(32); 
            }
        }
        h3 {
            line-height: 90%;
            color: $grey;
            @include font-size(40);
        }
        h4 {
            display: inline-block;
            max-width: 220px;
            margin-top: 5px;
            font-weight: 700;
            @include font-size(18);            
            @media screen and (max-width: 768px) {
                margin-top: 25px;
                max-width: none;
            }
        }
        @media screen and (max-width: 768px) {
            h2, h3, h4 {
                display: block;
                margin-left: 0;
                margin-right: 0;
                max-width: none;
                text-align: center !important;
            }
        }
    }

    .triangle {
        &.home__triangle-1 {
            top: 100px;
            left: 70px;
        }
        &.home__triangle-2 {
            top: 500px;
            left: 30px;
            transform: rotate(-20deg);
            width: 35px;
            height: 35px;
        }
        &.home__triangle-3 {
            top: 850px;
            transform: rotate(-20deg) scale(.8);
            left: 70px;
        }
        &.home__triangle-4 {
            top: 250px;
            right: -80px;
            transform: rotate(50deg) scale(.7);
        }
        &.home__triangle-5 {
            top: 550px;
            right: -50px;
            transform: rotate(20deg);
        }
        &.home__triangle-6 {
            top: 900px;
            right: -80px;
            transform: rotate(10deg);
        }
        &.home__triangle-7 {
            top: 50px;
            left: 150px;
            transform: rotate(-20deg);
        }
        &.home__triangle-8 {
            top: 80px;
            left: 500px;
            transform: rotate(-50deg);
            width: 50px;
            height: 50px;
        }
        &.home__triangle-9 {
            top: -100px;
            left: 300px;
            transform: rotate(15deg);
        }
        &.home__triangle-10 {
            top: 60px;
            left: -50px;
            width: 65px;
            height: 65px;
            transform: rotate(40deg);
        }        
        &.home__triangle-11 {
            top: -40px;
            left: 350px;
            width: 65px;
            height: 65px;
            transform: rotate(20deg);
        }        
        &.home__triangle-12 {
            top: 100px;
            left: -50px;
            width: 65px;
            height: 65px;
            transform: rotate(40deg);
        }                
        &.home__triangle-13 {
            top: -200px;
            left: 370px;
            transform: rotate(20deg);
        }        
        &.home__triangle-14 {
            top: 50px;
            left: -50px;
            width: 65px;
            height: 65px;
            transform: rotate(20deg);
        }                
        &.home__triangle-15 {
            top: -200px;
            left: 370px;
            width: 40px;
            height: 40px;
            transform: rotate(-20deg);
        }                
        &.home__triangle-16 {
            top: -150px;
            left: 800px;
            width: 40px;
            height: 40px;
            transform: rotate(20deg);
        }                
        &.home__triangle-17 {
            top: -40px;
            left: 1100px;
            width: 30px;
            height: 30px;
        }               
        &.home__triangle-18 {
            top: 210px;
            left: 920px;
            transform: rotate(-10deg);
        }
        @media screen and (max-width: 600px) {
            display: none;    
        }
    }
    
    #enquire {
        display: none;
        padding: 50px;
        overflow: visible;
        .enquire__logo {
            position: absolute;
            z-index: 600;
            left: 0;
            top: -85px;
            height: 55px;
        }
        .fancybox-close-small {
            width: 55px;
            height: 55px;
            padding: 0;
            top: -85px;
            background: url('/images/close.svg') center center no-repeat;
            background-size: cover;
            svg {
                display: none;
            }
        }
        h2 {            
            margin-bottom: 25px;
            @include font-size(28);
        }
        form {
            margin-top: 35px;
        }
        p {
            margin: 0 0 15px;
            display: flex;
            span {
                width: calc(100% - 130px);
            }
            &:nth-of-type(5) {
                display: block;
                label {
                    display: block;
                    span {
                        width: 100%;
                    }
                }
            }
            &:last-of-type {
                margin: 0;
            }
        }
        label {
            display: inline-block;
            width: 130px;
            margin: 0;
            line-height: 100%;
            color: $green;
            @include font-size(22);
            @extend %condensed-font;
        }
        input:not([type=submit]), textarea {
            width: 100%;
            padding: 5px 15px;
            border: none;
            resize: none;
            border-bottom: 1px solid $purple;
            &:focus {
                outline: none;
            }
        }
        textarea {
            height: 150px;
            padding: 5px 0;
        }
        div.wpcf7-response-output {
            margin: 35px 0 0 0;
        }
        @media screen and (max-height: 900px) {
            top: 40px;
            textarea {
                height: 80px;
            }
        }
    }
    
}