%display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;   
}

%condensed-font {
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}