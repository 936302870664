.apartments__intro {
    h1 {
        max-width: 450px;
        color: $green;
    }
    h2 {
        max-width: 820px;
        color: $slate;
    }
    @media screen and (max-width: 600px) {
        padding-top: 25px;    
    }
    img {
        min-width: 100%;
    }
}

.apartments__triangle-1 {
    top: 600px;
    left: 70px;
    transform: rotate(10deg);
}
.apartments__triangle-2 {
    bottom: 170px;
    right: 150px;
    width: 35px;
    height: 35px;
    transform: rotate(10deg);
}
.apartments__triangle-3 {
    top: 80px;
    right: 200px;
    transform: rotate(-20deg);
}
.apartments__triangle-4 {
    top: 320px;
    right: 40px;
    transform: rotate(-35deg);
}