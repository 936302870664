.community__intro {
    h1 {
        max-width: 500px;
        color: $plum;
    }
    h2 {
        max-width: 625px;
        color: $slate;
    }
    .community__intro--image2 {
        margin-top: 0;
    }
    .community__icons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0;
        max-width: 1000px;
        figure {
            text-align: center;
            img {
                width: 100px;
                height: 100px;
                margin: 0;
            }
            figcaption {
                margin-top: 0;
                line-height: 120%;
                font-weight: 700;
                color: $slate;
                @include font-size(20); 
                @extend %condensed-font;
                span {
                    display: block;
                    margin-bottom: 5px;
                    color: $green;
                    @include font-size(42);
                }
            }
        }
    }
}

.community__triangle-1 {
    top: 500px;
    left: 300px;
    width: 35px;
    height: 35px;
    transform: rotate(25deg);
}
.community__triangle-2 {
    top: 700px;
    left: 100px;
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
}