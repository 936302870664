/* Colours */
$green:      #009B3E;
$purple:     #5C2684;
$orange:     #F07D00;
$red:        #D70826;
$plum:       #881161;
$lime:       #80A41B;
$pink:       #BB88AA;
$fuschia:    #E2378D;
$grey:       #232F3B;
$light_grey: #A0A3A4;
$slate:      #755F67;