html {
    background-color: #FFFFFF;
    margin-top: 0 !important;
}

body {
    overflow-x: hidden;
    max-width: 1800px;
    margin: 0 auto !important;
    padding-top: 140px;
    background: #FFF;
    @media screen and (max-width: 600px) {
        padding-top: 50px;    
    }
}

img {
    max-width: 100%;
}

.wrapper {
    max-width: 1400px;
    padding: 0 45px;
    margin: 0 auto;
    &.narrow {
        max-width: 850px;
    }
    &.centered {
        text-align: center;
        @media screen and (max-width: 400px) {
            text-align: left;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0 25px;    
    }
}

p.intro {
    font-weight: 600;
    @include font-size(14);
}

header.header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding-top: 19px;
    background: #FFF;
    transition: all .2s ease;
    .wrapper {
        position: relative;
        & > p {
            position: absolute;
            top: 7px;
            right: 190px;
            font-family: acumin-pro-condensed, sans-serif;
            font-weight: 800;
            color: $lime;
            transition: all .2s ease;
            @include font-size(11);
            @media screen and (max-width: 768px) {
                display: none;    
            }
        }
    }
    .header__logo svg {
        width: 280px;
        transition: width .2s ease;
    }
    .header__triangle, .header__menu {
        position: absolute;
        top: -55px;
        right: 25px;
        width: 144px;
        height: 150px;
    }
    .header__triangle {   
        position: fixed;
        z-index: 495;
        top: -39px;
        width: 1296px;
        height: 1350px;
        transform-origin: top right;
        transition: all .5s ease;
        transform: scale(.1111111);
        path {
            transition: fill .5s ease;
        }
        @media screen and (min-width: 1400px) {
            right: calc((100vw - 1400px) / 2 + 15px);
        }
    }
    .header__overlay {
        position: absolute;
        z-index: 490;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background: rgba($grey, .98);
        opacity: 0;
        transition: opacity .5s ease;        
    }
    .header__menu {
        z-index: 500;        
        padding: 45px 0 0 35px;
        color: #FFF;
        @include font-size(20);
        span {
            text-transform: uppercase;
            @include font-size(9);
        }
        &:hover {
            text-decoration: none;
        }
        &.open {
            display: none;
            & + .header__triangle {
                left: 50%;
                transform: scale(1) rotate(-25deg) translate(-50%, -750px);
                path {
                    fill: #FFF;
                }
            }
            & ~ nav {
                display: block;
                position: fixed;
                z-index: 500;                
                top: 120px;
                left: 50%;
                width: 700px;
                height: auto;
                padding-left: 140px;
                transform: translateX(-50%);
                animation-name: fadeIn;
                animation-delay: .25s;
                animation-fill-mode: forwards;
                animation-duration: .25s;
            }
            & ~ .header__close {
                position: fixed;
                width: 70px;
                height: 70px;
                opacity: 1;
            }
            & ~ .header__overlay {
                position: fixed;
                opacity: 1;
                width: 100%;
            }
            @media screen and (max-width: 600px) {
                & ~ nav {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    padding: 80px 0 0;
                    transform: none;
                    background: #FFF;
                    animation-delay: 0s;
                    p {                        
                        padding: 25px;
                        margin: 0;
                        text-align: center;
                        @include font-size(16);
                    }
                    ul li {
                        margin: 0;
                        a {
                            max-width: none;
                            padding: 20px 25px;
                            text-align: center;
                            @include font-size(20);
                        }
                    }
                }
                & ~ .header__close {
                    top: 25px;
                    right: 25px;
                    width: 45px;
                    height: 45px;
                    transform: none;
                }
                & ~ .header__overlay {
                    display: none;    
                }
            }
        }
    }
    nav {
        display: none;
        opacity: 0;
        transition: opacity .5s ease;
        p {
            margin-bottom: 35px;
            font-family: acumin-pro-condensed, sans-serif;
            font-weight: 800;
            color: $plum;
            @include font-size(20);
        }
        ul {            
            li {
                margin-bottom: 35px;
                a {
                    display: block;
                    position: relative;
                    max-width: 300px;
                    line-height: 90%;
                    color: $grey;
                    transition: all .2s ease;
                    @include font-size(30);
                    @extend %condensed-font;
                    &:hover, &.active {
                        text-decoration: none;
                        color: $lime;
                        &:before {
                            display: block;
                        }
                    }
                    &:before {
                        display: none;
                        content: "";
                        position: absolute;
                        left: -80px;
                        top: 7px;
                        width: 60px;
                        height: 35px;
                        background: url('/images/arrow.svg') center center no-repeat;
                        background-size: cover;
                    }
                    @media screen and (max-width: 1024px) {
                        @include font-size(24);    
                    }
                }
            }
        }
    }
    .header__close {
        position: absolute;
        z-index: 501;
        top: 50px;
        right: 50%;
        transform: translateX(calc(-50% + 500px));
        width: 0;
        opacity: 0;
        background: url('/images/close2.svg') center center no-repeat;
        background-size: cover;
        transition: opacity .3s ease;
    }
    &.sticky {
        height: 50px;
        padding-top: 0;
        .header__logo svg {
            width: 200px;
            path:last-child {
                display: none;
            }
        }
        .wrapper > p {
            margin-top: 3px;
            color: $grey;
        }
        .header__triangle {
            top: -155px;
        }
        .header__menu {
            margin-top: 5px;
            svg path {
                fill: $grey;
            }
            &.open + .header__triangle {
                transform: scale(1) rotate(-25deg) translate(-54%, -645px);
            }
            @media screen and (max-width: 600px) {
                margin-top: 0;
                svg path {
                    fill: #FFF;
                }
            }
        }
        
    }
    @media screen and (max-width: 600px) {
        height: 50px;  
        padding-top: 0;
        .header__logo svg {
            width: 200px; 
            height: 50px;
            margin: 0;
        }
        .header__triangle {
            display: none;
        }
        p {
            padding-top: 22px;
            font-weight: 700;
            @include font-size(10);
        }
        .header__menu {
            background: $grey;
            height: 50px;
            width: 70px;
            top: 0;
            right: 0;
            padding: 0;
            text-align: center;
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.triangle {
    position: absolute;  
    width: 29px;
    height: 29px;
    svg {
        width: 100%;
        height: 100%;
    }
    &.green path {
        fill: $green;
    }      
    &.orange path {
        fill: $orange;
    }    
    &.plum path {
        fill: $plum;
    }
    &.fuschia path {
        fill: $fuschia;
    }
    &.purple path {
        fill: $purple;
    }
    &.red path {
        fill: $red;
    }
    &.rotate svg {
        animation-name: rotate;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: center;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

main {
    position: relative;
    z-index: 100;
}

.page__content {
    position: relative;
    max-width: 1400px;
    padding-top: 50px;
    margin: 0 auto;
    h1 {
        margin-bottom: 35px;
        line-height: 90%;
        & + h2 {
            margin-top: -20px;
        }
    }
    img {
        margin: 50px 0;
    }
    p {
        max-width: 700px;
        @include font-size(15);
    }
    @media screen and (max-width: 1024px) {        
        p {
            @include font-size(13);
        }
    }
    @media screen and (max-width: 600px) {
        img {
            margin: 35px 0;
        }
        p {
            @include font-size(12);
        }
    }
}

.enlarge-image {
    display: block;
    position: relative;
    .icon {
        position: absolute;
        bottom: 0;
        left: 35px;
        width: 80px;
    }
    &.right .icon {
        left: auto;
        right: 35px;
    }
}

#cookie-notice .cn-button {
    background-color: #BC87AA;
}

.grecaptcha-badge {
    visibility: hidden;
}

.centre-aligned {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}

.carousel-indicators {
    bottom: 75px;
    li {
        width: 120px;
        background: #FFF;
        border-top: 0;
        margin: 0 5px;
    }
}

.footer__contact {
    margin-top: 100px;
    padding: 25px 0;
    background: $pink;
    h2 {
        max-width: 350px;
        font-weight: 700;
        color: #FFF;
        @include font-size(38);
        @media screen and (max-width: 1024px) {  
            @include font-size(28);  
        }
        @media screen and (max-width: 767px) {
            margin-bottom: 25px;
            max-width: none;
            text-align: center;
            @include font-size(26);
        }
    }
    .grecaptcha {
        @include font-size(7);
    }
    form {
        .row > div {
            position: relative;
            & > span {
                position: absolute;
                top: 8px;
                right: 15px;  
                display: block;
                width: calc(100% - 140px);
            }
        }
        label {
            width: 110px;
            color: #FFF;
            @include font-size(18);
            @extend %condensed-font;
            font-weight: 700;
        }
        input:not([type=submit]) {
            width: 100%; 
            background: transparent;
            border-bottom: 1px solid #FFF;
            color: #FFF;
            &:focus {
                outline: none;
            }
        }
        input[type=submit] {
            margin-top: 20px;
            background: transparent;
            border-color: #FFF;
            color: #FFF !important;
            @media screen and (max-width: 767px) {
                width: 100%;    
            }
        }
        div.wpcf7-response-output {
            margin: 25px 0 0;
            padding: 10px 20px;
            color: #FFF;
            border: 1px solid #FFF;
        }
        span.wpcf7-not-valid-tip {
            color: $red;
            @include font-size(8);
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: 50px;    
    }
}

footer.footer {
    padding: 15px 0;
    background: $grey;
    color: #FFF;
    p {
        @include font-size(9);
        @media screen and (max-width: 768px) {
            text-align: center;            
        }
        a {
            color: #FFF;
        }
    }
}

.button, input[type=submit], button {
    display: inline-block;
    padding: 5px 20px;
    border: 1px solid $grey;
    background: #FFF;
    text-decoration: none !important;
    color: $grey !important;
    transition: all .2s ease;
    @include font-size(16);
    @extend %condensed-font;
    &:hover {
        background: $grey;
        color: #FFF !important;
    }
}

.enllax {
    min-height: 800px;
}

.fancybox-bg {
    background: $grey;
}
.fancybox-is-open .fancybox-bg {
    opacity: .99;
}
.fancybox-button {
    color: #FFF !important;
}