.students__intro {
    h1 {
        max-width: 500px;
        color: $purple;
        @media screen and (max-width: 1024px) {
            max-width: 410px;
        }
    }
    h2 {
        max-width: 930px;
        color: $slate;
        @media screen and (max-width: 1024px) {
            max-width: 750px;
        }
    }
    .students__intro--image1 {
        margin-bottom: 25px;
    }
    .students__intro--image2 {
        margin-top: 0;
    }
    dl {
        display: flex;
        justify-content: space-between;
        width: 300px;
        dt, dd {
            line-height: 100%;
            @extend %condensed-font;      
        }
        dt {
            color: $fuschia;
            @include font-size(88);
        }
        dd {
            max-width: 150px;
            padding-top: 42px;
            margin: 0;
            color: $slate;
            @include font-size(28); 
        }
    }
    @media screen and (max-width: 768px) {
        padding-top: 25px;  
        dl {
            width: 270px;
            margin-bottom: 40px;
            dt {
                @include font-size(64);
            }
            dd {
                padding-top: 20px;
                @include font-size(24);
            }
        }
    }
}

.students__accommodation {
    h2 {        
        color: $slate;
        @include font-size(56);
        @media screen and (max-width: 1024px) {
            @include font-size(42);    
        }
        @media screen and (max-width: 768px) {
            @include font-size(28);    
        }
    }
    h3 {
        color: $plum;
        @include font-size(36);
        @media screen and (max-width: 768px) {
            margin-top: 15px;  
            @include font-size(22);
        }
    }
    h4 {        
        margin-bottom: 15px;
        color: $slate;
        @include font-size(18);
        @media screen and (max-width: 768px) {
            margin-top: 35px;  
        }
    }
    ul li {
        list-style: disc;
        margin-left: 20px;
    }
    #students__carousel {
        margin: 50px 0;
        img {
            margin: 0;
        }        
    }
    .students__accommodation--rooms {
        img {
            margin-bottom: 0;
        }
        @media screen and (max-width: 768px) {
            .row > div {
                &:nth-child(1) {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 2;
                }
                &:nth-child(3) {
                    order: 4;
                }
                &:nth-child(4) {
                    order: 3;
                }
                &:nth-child(5) {
                    order: 5;
                }
                &:nth-child(6) {
                    order: 6;
                }
                &:nth-child(7) {
                    order: 9;
                }
                &:nth-child(8) {
                    order: 8;
                }
            }    
        }
    }
}

.students__location {
    margin-top: 100px;
    h2 {
        margin-bottom: 35px;
        color: $purple;
        @include font-size(56);
        @media screen and (max-width: 1024px) {
            @include font-size(42);    
        }
    }
    p {
        max-width: 500px;
        @media screen and (max-width: 768px) {
            max-width: none;    
        }
    }
    .students__location--local-amenities {
        p {            
            margin-top: 10px;
            font-weight: 700;
            @include font-size(11);
        }
        .students__location--logos {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .logo {
                flex-basis: 45%;
                img {
                    max-width: 150px;
                    margin: 35px 0 0;
                }
            }
        }
    }
    .students__location--transport {
        text-align: center;
        p {
            max-width: none;
            margin-top: 100px;
            font-weight: 700;
            @include font-size(13);
        }
        .students__location--icons {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 35px auto 100px;
            max-width: 900px;
            figure {
                text-align: center;
                img {
                    width: 100px;
                    height: 112px;
                    margin: 0;
                }
                figcaption {
                    margin-top: 5px;
                    line-height: 120%;
                    @include font-size(18); 
                    @extend %condensed-font;
                    span {
                        display: block;
                        font-weight: 700;
                        @include font-size(32);
                    }
                }
                &:nth-child(1) {
                    figcaption {
                        color: $green;
                        span {
                            color: $slate;
                        }
                    }
                }
                &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    figcaption {
                        padding-top: 5px;
                        color: $slate;
                        span {
                            padding-bottom: 10px;
                            color: $green;
                        }
                    }
                }
                @media screen and (max-width: 600px) {
                    flex-basis: 48%;
                    figcaption {
                        @include font-size(14);
                        span {
                            @include font-size(24);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: 50px;
        h2 {
            @include font-size(28);
        }
        .students__location--local-amenities p { 
            margin-top: 35px;
        }
    }
}

.students__contact {
    padding: 30px 0;
    .wrapper > .row > .col-md-7 {
        display: flex;
        align-items: center;
    }
    a {
        text-decoration: none !important;
        &:hover h2:after {
            right: 0;
        }
    }
    h2 {
        position: relative;
        max-width: 570px;
        padding-right: 100px;        
        @include font-size(31);
        &:after {
            content: "";
            position: absolute;
            top: 3px;
            right: 10px;
            display: block;
            width: 80px;
            height: 50px;
            background: url('/images/footer__arrow.svg') right center no-repeat;
            background-size: cover;
            transition: right .2s ease;
        }
    }
    img {
        max-width: 50%;
    }
    @media screen and (max-width: 1024px) {
        h2 {
            max-width: 470px;
            margin: 25px 0 0;
            text-align: left;
            @include font-size(24);
            &:after {
                top: -2px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        h2 {
            margin: 0;
            text-align: left;
            @include font-size(24);
            &:after {
                top: 14px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        h2 {
            margin: 25px 0 0;
        }
    }
}

.students__triangle-1 {
    top: 450px;
    right: 100px;
    width: 35px;
    height: 35px;
    transform: rotate(25deg);
}
.students__triangle-2 {
    bottom: 250px;
    left: 100px;
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
}
.students__triangle-3 {
    top: 70px;
    right: 50px;
    width: 35px;
    height: 35px;
    transform: rotate(30deg);
}
.students__triangle-4 {
    top: 350px;
    left: 250px;
    transform: rotate(15deg);
}
.students__triangle-5 {
    top: 70px;
    left: 80px;
    width: 45px;
    height: 45px;
    transform: rotate(-10deg);
}
.students__triangle-6 {
    top: 350px;
    left: 300px;
    transform: rotate(15deg);
}
.students__triangle-7 {
    top: 350px;
    right: 50px;
    width: 35px;
    height: 35px;
    transform: rotate(25deg);
}
.students__triangle-8 {
    top: 350px;
    left: 150px;
    width: 45px;
    height: 45px;
    transform: rotate(25deg);
}