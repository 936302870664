@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-25%,0,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(25%,0,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0,-25%,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,25%,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

