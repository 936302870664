/* Typography */
body {
    font-family: acumin-pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: $grey;
}

h1, h2, h3, h4, h5, h6 {
    @extend %condensed-font;
    font-style: normal;
    line-height: 100%;
    margin: 0;
}

h1 {
    @include font-size(56);
    @media screen and (max-width: 1024px) {
        @include font-size(42);
    }
    @media screen and (max-width: 600px) {
        @include font-size(38);
    }
}

h2 {
    @include font-size(40);    
    @media screen and (max-width: 1024px) {
        @include font-size(32);
    }
    @media screen and (max-width: 600px) {
        @include font-size(24);
    }
    @media screen and (max-width: 400px) {
        @include font-size(20);
    }
}

h3 {
    @include font-size(22);
    @media screen and (max-width: 1024px) {
        @include font-size(19);
    }
    @media screen and (max-width: 600px) {
        @include font-size(16);
    }
    @media screen and (max-width: 400px) {
        @include font-size(16);    
    }
}

h4 {
    @include font-size(16);
    @media screen and (max-width: 1024px) {
        @include font-size(14);
    }
    @media screen and (max-width: 600px) {
        @include font-size(12);    
    }
    @media screen and (max-width: 400px) {
        @include font-size(12);    
    }
}

h5 {
    @include font-size(14);
    @media screen and (max-width: 1024px) {
        @include font-size(12);
    }
}

h6 {
    @include font-size(12);
}

p, li {    
    margin-bottom: 1em;
    @include font-size(11);
    &:last-of-type {
        margin-bottom: 0;
    }    
    @media screen and (max-width: 1024px) {
        @include font-size(10);
    }
}